import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../assets/maaz-logo.png";
import CountryIcon from "../assets/coronavirus.png";
import CategoriesIcon from "../assets/categories.png";
import ExpenseIcon from "../assets/expenses.png";
import {
  closeSidebar,
  handleSelectedCom,
  handleSidebarToggle,
} from "./toggleSlice";
import ToggleSide from "./ToggleSide";
import {
  BankIcon,
  CategoryIcon,
  ClientIcon,
  CompanyProfile,
  DashboardIcon,
  LogoutIcon,
  PaymentIcon,
  PersonPin,
  SaleIcon,
  StockIcon,
} from "../assets/Svg";

const drawerWidth = "232px";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "#FFFFFF",
  boxShadow: "0px 3.57397px 40.2071px rgba(0, 0, 0, 0.08)",
  height: "100vh",
  // padding: "0px 24px",
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainStyle = styled(Box)((props) => ({
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100vh",
  padding: props.open ? "8px 24px 0 10px" : "0px 0 0 0",
  width: props.open ? "100%" : "32px",
}));
const LogoBox = styled(Box)({
  display: "flex",
  borderRadius: "4px",
  marginBottom: "8px",
  justifyContent: "space-between",
  alignItems: "center",
});

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sidebarOpen, selectedCom } = useSelector((state) => state.sidebar);
  const theme = useTheme();

  const isExactMedium = useMediaQuery(theme.breakpoints.up("md"));

  const isBelowMedium = useMediaQuery(theme.breakpoints.down("md"));

  let justifyContent = sidebarOpen ? "initial" : "center";
  const ItemBox = styled(Box)((props) => ({
    display: "flex",
    minHeight: "40px",
    alignItems: "center",
    borderRadius: "4px",
    marginBottom: "15px",
    justifyContent: sidebarOpen ? "initial" : "center",
    background: props.curr == selectedCom ? "#008890" : "#f6f6f6",
    color: props.curr == selectedCom ? "#000" : "#000",
    cursor: "pointer",
    "&:hover": {
      background: props.curr == selectedCom ? "#008890" : "#EFEFEF",
      color: props.curr == selectedCom ? "#000" : "#000",
    },
  }));

  useEffect(() => {
    if (isBelowMedium && sidebarOpen) {
      toggle();
    } else if (isExactMedium && !sidebarOpen) {
      dispatch(handleSidebarToggle());
    }
  }, [isBelowMedium, isExactMedium]);

  const toggle = () => {
    dispatch(closeSidebar());
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleSelection = (arg) => {
    dispatch(handleSelectedCom(arg));
  };

  const iconStyle = {
    pl: sidebarOpen ? 1.6 : 0,
    minWidth: 0,
    mr: sidebarOpen ? 0.8 : "auto",
    ml: sidebarOpen ? 0 : 0.8,
    justifyContent: "center",
  };

  const SubTitle = styled(ListItemText)({
    fontWeight: 500,
    fontSize: "5px",
    cursor: "pointer",
    opacity: sidebarOpen ? 1 : 0,
  });

  return (
    <DrawerBox
      variant="permanent"
      open={sidebarOpen}
      sx={{ position: "relative" }}>
      <MainStyle open={sidebarOpen}>
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block", borderRadius: "4px", mb: 1.6 }}>
            <LogoBox>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  width: "100%",
                  mr: sidebarOpen ? 0 : "auto",
                  justifyContent: "center",
                }}>
                {sidebarOpen ? (
                  <Stack
                    direction="row"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between">
                    <Stack direction="row">
                      <img src={Logo} alt="logo" width={"150"} height={"40"} />
                    </Stack>
                    <ToggleSide />
                  </Stack>
                ) : (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}>
                    <ToggleSide />
                    <img src={Logo} alt="logo" width={"40"} height={"12"} />
                  </Stack>
                )}
              </ListItemIcon>
            </LogoBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"dashboard"}
              onClick={() => handleSelection("dashboard")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "dashboard" ? (
                  <Stack sx={{ color: "#000" }}>
                    <DashboardIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <DashboardIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontFamily: "Raleway", fontSize: "16px" },
                }}>
                Dashboard
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox curr={"sale"} onClick={() => handleSelection("sale")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "sale" ? (
                  <Stack sx={{ color: "#000" }}>
                    <SaleIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <SaleIcon />
                  </Stack>
                )}
              </ListItemIcon>

              <SubTitle
                primaryTypographyProps={{
                  style: { fontFamily: "Raleway", fontSize: "16px" },
                }}>
                Sale
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"payment"}
              onClick={() => handleSelection("payment")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "payment" ? (
                  <Stack sx={{ color: "#000" }}>
                    <PaymentIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <PaymentIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                C. Payment
              </SubTitle>
            </ItemBox>
          </ListItem>
          
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox curr={"client"} onClick={() => handleSelection("client")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "client" ? (
                  <Stack sx={{ color: "#000" }}>
                    <ClientIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <ClientIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Client
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"products"}
              onClick={() => handleSelection("products")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "products" ? (
                  <Stack sx={{ color: "#000" }}>
                    <CategoryIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <CategoryIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Products
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"provider"}
              onClick={() => handleSelection("provider")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "provider" ? (
                  <Stack sx={{ color: "#000" }}>
                    <PersonPin />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <PersonPin />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Supplier
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox curr={"stock"} onClick={() => handleSelection("stock")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "stock" ? (
                  <Stack sx={{ color: "#000" }}>
                    <StockIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <StockIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Purchase
              </SubTitle>
            </ItemBox>
          </ListItem>
          
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"supplier_payment"}
              onClick={() => handleSelection("supplier_payment")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "supplier_payment" ? (
                  <Stack sx={{ color: "#000" }}>
                    <PaymentIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <PaymentIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                S. Payment
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox curr={"bank"} onClick={() => handleSelection("bank")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "bank" ? (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Bank
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"bank_transfer"}
              onClick={() => handleSelection("bank_transfer")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "bank_transfer" ? (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Bank Transfer
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox curr={"cash"} onClick={() => handleSelection("cash")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "cash" ? (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Cash
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox curr={"cashWithdrawal"} onClick={() => handleSelection("cashWithdrawal")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "cashWithdrawal" ? (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <BankIcon />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Cash Withdrawal
              </SubTitle>
            </ItemBox>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"country"}
              onClick={() => handleSelection("country")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "country" ? (
                  <Stack sx={{ color: "#000" }}>
                    <img src={CountryIcon} alt="country" height={"20"} />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <img src={CountryIcon} alt="country" height={"20"} />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Country
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"category"}
              onClick={() => handleSelection("category")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "category" ? (
                  <Stack sx={{ color: "#000" }}>
                    <img src={CategoriesIcon} alt="country" height={"20"} />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <img src={CategoriesIcon} alt="country" height={"20"} />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Categories
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"expense"}
              onClick={() => handleSelection("expense")}>
              <ListItemIcon sx={iconStyle}>
                {(
                  <Stack sx={{ color: "#000" }}>
                    <img src={ExpenseIcon} alt="expense" height={"20"} />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Expense
              </SubTitle>
            </ItemBox>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"users"}
              onClick={() => handleSelection("users")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "users" ? (
                  <Stack sx={{ color: "#000" }}>
                    <CompanyProfile />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <CompanyProfile />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Users
              </SubTitle>
            </ItemBox>
          </ListItem>
          {/* <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              curr={"profile"}
              onClick={() => handleSelection("profile")}>
              <ListItemIcon sx={iconStyle}>
                {selectedCom == "profile" ? (
                  <Stack sx={{ color: "#000" }}>
                    <CompanyProfile />
                  </Stack>
                ) : (
                  <Stack sx={{ color: "#000" }}>
                    <CompanyProfile />
                  </Stack>
                )}
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}>
                Company Profile
              </SubTitle>
            </ItemBox>
          </ListItem> */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ItemBox
              sx={{ justifyContent, background: "#f6f6f6" }}
              onClick={handleLogout}>
              <ListItemIcon sx={iconStyle}>
                <Stack sx={{ color: "#000" }}>
                  <LogoutIcon />
                </Stack>
              </ListItemIcon>
              <SubTitle
                primaryTypographyProps={{
                  style: { fontSize: "16px", fontFamily: "Raleway" },
                }}
                sx={{ color: "#000" }}>
                Logout
              </SubTitle>
            </ItemBox>
          </ListItem>
        </List>
      </MainStyle>
    </DrawerBox>
  );
}

export default Sidebar;
